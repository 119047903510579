@font-face {
  font-display: block;
  font-family: 'Pincoyablack';
  src: url(/fonts/pincoyablack.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: normal;
  src: url(/fonts/Raleway-Regular.ttf) format('truetype');
}

$sans: "Optima", sans-serif;
$serif: "Baskerville", serif;
$mono: "Courier", monospace;
//$heading-font-family: "Verdana", sans-serif;
$header-title-font-family: "Pincoyablack", "Futura", sans-serif;
$header-subtitle-font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;;
