@charset "utf-8";

// Our variables
$base-font-family: 'Raleway', Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;

:root {
  --text-color:       #111;
  --background-color: #fdfdfd;
  --brand-color:      #2a7ae2;
  --brand-color-dark: #1756a9;

  --grey-color:       #828282;
  --grey-color-light: #e8e8e8;
  --grey-color-dark:  #424242;
}

@media (prefers-color-scheme: dark) {
  :root {
    --text-color:       #dfdfdf;
    --background-color: #191919;
    --brand-color:      #2a7ae2;
    --brand-color-dark: #5fa3fc;

    --grey-color:       #828282;
    --grey-color-light: #424242;
    --grey-color-dark:  #e8e8e8;
  }
}
// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: calc($spacing-unit / 2);
//         padding-left: calc($spacing-unit / 2);
//     }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
  "fonts",
  "base",
  "layout",
  "syntax-highlighting",
  "monokai",
  "syntax-additions",
  "video"
;

#discourse-comments {
  border-radius: 5px;
  border: 2px solid white;
  background: white;
}

picture img{
  margin-bottom: 1em;
  border-radius: 0.4em;
}

strong {
  font-weight: bold;
}
