$highlight-border-radius: 4px;

pre {
  overflow-x: auto;
}

.highlight {
  color: #eeeeee;
  border-radius: $highlight-border-radius;
}
