/**
 * Site header
 */
.site-header {
  border-top: 5px solid var(--grey-color-dark);
  border-bottom: 1px solid var(--grey-color-light);
  min-height: 56px;
}

.site-header .wrapper {
  display: flex;
  justify-content: space-between;
}

.site-title {
  font-size: 4em;
  font-family: $header-title-font-family;
  &,
  &:visited {
    color: var(--grey-color-dark);
  }
}

.site-nav {
  line-height: 56px;

  .page-link {
    color: var(--text-color);
    line-height: $base-line-height;
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid var(--grey-color-light);
  padding: $spacing-unit 0;
}

.footer-heading {
  font-size: 2em;
  margin-bottom: calc($spacing-unit / 2);
  font-family: $header-title-font-family;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  color: var(--grey-color);
  margin-left: -(calc($spacing-unit / 2));
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: calc($spacing-unit / 2);
  padding-left: calc($spacing-unit / 2);
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
}

.page-heading {
  font-size: 20px;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: var(--grey-color);
}

.post-link {
  display: block;
  font-size: 24px;
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  font-size: 42px;
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    font-size: 36px;
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    font-size: 32px;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }
}
